.time-line-card3 {
    width: 300px;
    height: 100px;
    flex-shrink: 0;
    border-radius: 11.293px;
    background: #292929;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-family:'Space Grotesk', sans-serif ;
    overflow: hidden; /* Ensure content is clipped within the card */
    
  }
  
  @media (min-width: 1024px) {
    .time-line-card3 {
        width: 350px; 
    }
  }
  
  .time-line-card3 .date {
    position: absolute;
    top: 35%;
    right: 25%;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 101%; /* 19.955px */
    letter-spacing: 0.198px;
    text-transform: capitalize;
}

.time-line-card3 .day {
    margin-top: 25px;
    margin-right: 50%;
    margin-left: -22.5%;
    color: transparent; /* Make the text color transparent */
    -webkit-text-stroke: 2px #E9E9E9; /* Add a stroke to the text */
    font-family: "Space Grotesk";
    font-size: 129.82px;
    font-style: normal;
    font-weight: 700;
    line-height: 101%; /* 161.418px */
    letter-spacing: 1.598px;
    text-transform: capitalize;
    -webkit-background-clip: text; /* Clip the background to the text */
    background-clip: text; /* Standard property for background-clip */
    background-color: #E9E9E9; /* Set the background color to match the section */
}

.time-line-card3 .text {
    margin-left: 50px;
    margin-right: 8px;
    margin-top: 7.5%;
    margin-left: -5%;
    font-size: 24px;
    color: #FFF;
    font-style: normal;
    font-weight: 600;
    line-height: 101%; /* 33.402px */
    letter-spacing: 0.331px;
    text-transform: capitalize;
}

/* Media query for smaller screens */
@media (max-width: 1023px) {
    .time-line-card3 .date {
        font-size: 13px; /* Reduce font size for smaller screens */
    }

    .time-line-card3 .day {
        font-size: 110px; /* Reduce font size for smaller screens */
    }

    .time-line-card3 .text {
        font-size: 20px; /* Reduce font size for smaller screens */
        margin-left: 60px; /* Adjust margins for smaller screens */
        margin-right: 5px;
    }
}


  .time-line-card3 .inner-card{
    display: flex;
    flex-direction: row;

  }



  @media (max-width: 480px) {
    .special-card1 .date{
      right: 22.5%;
    }
    .special-card1 .day{
        margin-right: -45px;
      }
  }
  