.view-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #FBB934;
  overflow: hidden; /* Ensure that content doesn't overflow */
  padding: 4rem 0 7rem 0;
}

.background-text {
  position: absolute;
  top: 50%;
  left: 70%;
  transform: translate(-50%, -50%);
  font-size: 7rem;
  font-family: "Space Grotesk", sans-serif;
  color: #FFEACC; /* Light color to blend with the background */
  white-space: nowrap;
  pointer-events: none; /* Ignore mouse events */
  opacity: 0.3;
}

.background-text-second {
  position: absolute;
  top: 85%;
  left: 30%;
  transform: translate(-50%, -50%);
  font-size: 7rem;
  font-family: "Space Grotesk", sans-serif;
  color: #FFEACC; /* Light color to blend with the background */
  white-space: nowrap;
  pointer-events: none; /* Ignore mouse events */
  opacity: 0.3;
}

.faq-title {
  font-size: xxx-large;
  font-family: "Space Grotesk", sans-serif;
  color: white;
  margin-bottom: 50px;
  margin-top: 10px;
}

.card-gallery {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
  top: 100px;
}

.row1{
  display: flex;
  justify-content: space-evenly;
  width: 90%;
}
/* .row2{
  width: 90%;
  display: flex;
  justify-content: space-around;
  margin-top: -5%;
} */
.card-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  left: 5%;
}

@media (max-width: 600px) {
  .card-one{
    margin-left: 0px;
  }
  .card-row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; /* Stack cards vertically */
    gap: 20px; /* Add some gap between cards */
    margin-bottom: 20px;
    width: 100%;
  }

  .background-text, .background-text-second {
    font-size: 5.3rem;
  }
}

.row1 {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Creates 2 equal-width columns */
  gap: 20px; /* Optional: space between the columns */
  margin-bottom: 10%;
}

.row2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Creates 3 equal-width columns */
  gap: 20px; /* Optional: space between the columns */
}


@media (max-width: 768px) {
  .row1, .row2 {
    display: flex;
    flex-direction: column; /* Stack columns vertically */
    justify-content: center;
    align-items: center;    /* Center the content horizontally */
    gap: 50px; /* Keep space between the items */
  }

  .row1 > div, .row2 > div {
    width: 100%; /* Make each column take the full width */
  }
}