.main-container-se{
  overflow: hidden;
}
/* Base styles are already set. Add media queries for smaller screens. */
@media (max-width: 768px) {

   .text-container {
      position: relative;
       width: 50%;
   }
   .heading {
     font-size: 1rem; /* Smaller heading for mobile */
   }
 
   .sub-text {
     font-size: 0.9rem; /* Smaller text for mobile */
   }
 
  
 
   .topdiv {
     height: 8%; /* Adjust the height for smaller screens */
     color: aquamarine;
   }
   #star{
    width: 30px !important;
   }
 }
 
.regBtn{
  display: flex;
  width : 180px;
  margin-right : 1.5em;
}

@media (max-width: 480px){
  .regBtn{
    display: flex;
    width : 180px;
    margin-right : 1em;
  }
}