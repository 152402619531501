/* Main container that holds everything and centers it */
.main-container-hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: black;
  color: white;
  position: relative;
  font-family: "Space Grotesk";
}

/* Container for the hero section */
.hero-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100vh;
  flex-direction: column;
}

/* Section that holds the text and image */
.section-one {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 60%;
  height: 80vh;
}

/* Styling for the text on the left and right */
#yellow-text-left, #yellow-text-right {
  font-size: 1.8rem;
  font-weight: bold;
  color: white;
  margin: 0 20px;
  position: absolute;
}

/* Positioning for the left text */
#yellow-text-left {
  left: 20%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* Positioning for the right text */
#yellow-text-right {
  right: 20%;
  top: 50%;
  transform: translate(50%, -50%);
}

/* Styling for the image in the center */
#girl-image {
  max-width: 20%;
  height: auto;
  z-index: 1;
  position: relative;
  padding-right: 25px;
}

/* Container for the logos at the bottom left */
.logo-container {
  position: absolute;
  bottom: 15px;
  left: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/* Styling for each logo image */
.logo-container img {
  height: 35px;
  padding: 0% 10px;
  max-height: 50px;
}

#wso2-logo{
  height: 45px;
}

#balerina-logo{
  height: 30px;
}

/* Scroll button styling (if any specific styling needed) */
.scroll-button {
  /* Add any specific styles for the scroll button here */
}/* Main container that holds everything and centers it */
.main-container-hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: black;
  color: white;
  position: relative;
}

/* Container for the hero section */
.hero-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100vh;
  flex-direction: column;
}

/* Section that holds the text and image */
.section-one {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 60%;
  height: 80vh;
}

/* Styling for the text on the left and right */
#yellow-text-left, #yellow-text-right {
  font-size: 2rem;
  font-weight: bold;
  color: white;
  margin: 0 20px;
  position: absolute;
}

/* Positioning for the left text */
#yellow-text-left {
  left: 33%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* Positioning for the right text */
#yellow-text-right {
  right: 30%;
  top: 50%;
  transform: translate(50%, -50%);
}

/* Styling for the image in the center */
#girl-image {
  max-width: 20%;
  height: auto;
  z-index: 1;
  position: relative;
}

/* Container for the logos at the bottom left */
/* .logo-container {
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
} */

/* Styling for each logo image */
/* .logo-container img {
  max-height: 40px;
  margin-right: 15px;
} */

/* Container for the scroll down button, positioned at the bottom right */
.scroll-button-container {
  /* border: 1px solid white; */
  position: absolute;
  /* gap: 20px; */
  bottom: 10px;
  right: 20px;
  width: 14%;
}

/* Scroll button styling (if any specific styling needed) */
.scroll-button {
  /* Add any specific styles for the scroll button here */
}

/* Media queries for responsive design */
@media (max-width: 768px) {
  /* Reduce text size for smaller screens */
  #yellow-text-left, #yellow-text-right {
    font-size: 1.5rem;
  }

  /* Reduce image size for smaller screens */
  #girl-image {
    max-width: 30%;
  }

  /* Adjust logo size for smaller screens */
  .logo-container img {
    max-height: 30px;
  }
}

@media (max-width: 480px) {
  /* Further reduce text size for mobile screens */
  #yellow-text-left, #yellow-text-right {
    font-size: 1.2rem;
  }

  /* Further reduce image size for mobile screens */
  #girl-image {
    max-width: 40%;
  }

  /* Further adjust logo size for mobile screens */
  .logo-container img {
    display: none;
    max-height: 25px;
  }

  .section-one {
    margin: 20px; 
  }
}

@media (min-width: 1400px) {
  .scroll-button-container {
    width: 12%;
  }
}

.partner{
  color: rgb(255, 255, 255);
  font-size: 0.6rem;
  /* position: absolute; */
  display: flex;
  gap: 10%;
  width: 100%;
  /* text-transform: uppercase; */
  /* top: 90%; */
  position: relative;
  left: 10%;
}
