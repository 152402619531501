.scroll-down-button {

    position: relative;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-around; 
    align-items: center;
    padding: 10px;
}

.scroll-down-button p{
    font-size: 0.9rem;
}