.prize-section {
    font-family: 'Space Grotesk';
    text-align: left;
    color: #ffffff;
    background-color: #0A1F25;
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    /* justify-content: center; */
    /* align-items: center; */
    overflow: hidden;
    /* height: 100vh; */
  }

  .prize-line{
    position: relative;
    /* display: flex; */
  }

  .prize-line-2{
    display: none;
  }

  .vector {
    position: relative;
  }

  .p-vector-1 {
    position: absolute;
    right: -3%;
    width: 15%;
    height: auto;
    top: -110px;
  }

  .p-vector-2 {
    position: absolute;
    left: -17.5%;
    bottom: -20px; /* Fine-tune the exact position */
    width: 20%;
    height: auto;
    top: 450px;
  }
  
  .prize-title {
    font-size: 3.5rem;
    display: flex;
    flex-direction: column;
    line-height: 100%;
    letter-spacing: 5px;
    margin-bottom: 3%;
  }
  
  .win-exciting {
    font-weight: 300;
    padding-top: 2.5%;
  }
  
  .prizes {
    font-weight: bold;
  }

  .prize-box {
    width: 110%;
    max-width: 260px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .prize-box img {
    max-width: 570px;
    width: 300px;
    height: auto;
  }

  .prize-info{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .prize-info h3 {
    font-family: "Space Mono";
    color: #222;
    background: linear-gradient(90deg, #FFB34A 0%, #00FFF8 100%);
    width: fit-content;
    padding: 0% 2%;
  }

  .prize-info p {
    letter-spacing: 1px;
    text-transform: capitalize;
  }

  .prize-set{
    display: flex;
    position: relative;
  }

  .first-place .prize-info{
    top: 0px;
  }

  .second-place .prize-info{
    top: 280px;
    left: -80px;
  }

  .third-place .prize-info{
    top: 360px;
    left: 115px;
  }

  .first-place img {
    width: 220%;
  }

  .first-place h3 {
    font-size: 1.3rem;
  }

  .first-place p {
    font-size: 1.9rem;
  }
  
  .second-place img {
    width: 200%;
  }

  .second-place h3 {
    font-size: 1.2rem;
  }
  
  .second-place p {
    font-size: 1.7rem;
  }

  .third-place img {
    width: 170%
  }

  .third-place h3 {
    font-size: 1.1rem;
  }

  .third-place p {
    font-size: 1.5rem;
  }

  .card-1{
    position: absolute;
    z-index: 10;
    left: 27.5%;
    padding-top: 4%;
  }
  
  .card-2{
    position: absolute;
    z-index: 5;
    left: 8%;
  }

  .card-3{
    position: absolute;
    z-index: 1;
    left: 46%;
    padding-top: 2%;
  }

  .additional-prizes{
    display: flex;
    flex-direction: column;
    text-transform: capitalize;
    gap: 2px;
    position: absolute;
    top: 150px;
    right: 150px;   
  }
  .additional-prizes img{
    max-width: 35px;
    height: auto;
  }

  .additional-prizes span:nth-child(2){
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: 1px; 
  }

  .additional-prizes span:nth-child(3){
    color: #FFB34A;
    font-size: 1.3rem;
    font-weight: 500;
    padding-bottom: 5%;
  }

  .additional-prizes span:nth-child(4){
    background: linear-gradient(100deg, #10C3BD 3.53%, #FBB934 63.81%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.2rem;
    font-weight: 500;
  }

  /* @media (max-width: 600px) {
    .prize-section {
        padding: 10px 20px;
    }

    .prize-title {
        font-size: 2.5rem;
    }
}

@media (min-width: 601px) and (max-width: 1024px) {
    .prize-section {
        padding: 15px 30px; 
    }

    .prize-title {
        font-size: 2.5rem;
    }
} */

@media (max-width: 768px) {
  .prize-section {
      padding: 10px 10px;
      gap: 50px;
      height: 75vh;
  }

  .prize-title {
      margin-left: -20%;
      font-size: 2.8rem !important;
  }

  .prize-line{
    display: none;
  }

  .prize-line-2{
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 50px;
  }
  .prize-box-2 {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .prize-info-2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:5px;
  }
  
  .prize-info-2 h3 {
    font-family: "Space Mono";
    color: #222;
    background: linear-gradient(90deg, #FFB34A 0%, #00FFF8 100%);
    width: fit-content;
    padding: 0% 2%;
    margin-left: 15%;
  }

  .prize-info-2 p {
    letter-spacing: 2px;
    text-transform: capitalize;
  }

  .prize-set-2{
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 40px;
  }

  .first-place-2 h3 {
    font-size: 1.1rem;
  }

  .first-place-2 p {
    font-size: 1.7rem;
  }

  .second-place-2 h3 {
    font-size: 1.1rem;
  }
  
  .second-place-2 p {
    font-size: 1.7rem;
  }

  .third-place-2 h3 {
    font-size: 1.1rem;
  }

  .third-place-2 p {
    font-size: 1.7rem;
  }

  .additional-prizes-2{
    display: flex;
    flex-direction: column;
    text-transform: capitalize;
    gap: 2px;
    text-align: center;
  }
  .additional-prizes-2 img{
    display: none;
  }

  .additional-prizes-2 span:nth-child(2){
    font-size: 1.3rem;
    font-weight: 500;
    letter-spacing: 1px; 
  }

  .additional-prizes-2 span:nth-child(3){
    color: #FFB34A;
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 5%;
  }

  .additional-prizes-2 span:nth-child(4){
    background: linear-gradient(100deg, #10C3BD 3.53%, #FBB934 63.81%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1rem;
    font-weight: 500;
  }

  .p-vector-1 {
    width: 70%;
    right: -75%;
    top: -490px;
  }

}

@media (min-width: 768px) and (max-width: 992px) {
  .prize-section {
      padding: 15px 30px;
  }

  .prize-title {
      font-size: 2.5rem;
  }

  .prize-box img {
      width: 80%;
  }

  .first-place img {
      width: 150%;
  }

  .second-place img {
      width: 140%;
  }

  .third-place img {
      width: 130%;
  }
}

@media (max-width: 1400px) {
  
  .prize-section {
      padding: 80px 100px;
  }

  .prize-title {
      font-size: 3.3rem;
  }

  .prize-box img {
      width: 100%;
  }

  .first-place img {
      width: 170%;
      height: 340px !important;
  }

  .second-place img {
      width: 165%;
  }

  .third-place img {
      width: 150%;
  }

  .first-place h3 {
    font-size: 1.15rem;
  }

  .first-place p {
    font-size: 1.7rem;
  }

  .second-place h3 {
    font-size: 1.05rem;
  }
  
  .second-place p {
    font-size: 1.6rem;
  }

  .third-place h3 {
    font-size: 0.95rem;
  }

  .third-place p {
    font-size: 1.4rem;
  }

  .additional-prizes{
    gap: 0.5px;
    position: absolute;
    top: 100px;
    right: 40px;   
  }

  .additional-prizes span:nth-child(2){
    font-size: 1.4rem;
    letter-spacing: 1px; 
    margin-top: 10px;
  }

  .additional-prizes span:nth-child(3){
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 5%;
  }

  .additional-prizes span:nth-child(4){
    font-size: 1.1rem;
    font-weight: 500;
  }
  .additional-prizes img{
    padding-bottom: 0%;
  }

  .third-place .prize-info{
    top: 320px;
  }

  .second-place .prize-info{
    top: 240px;
    left: -60px;
  }
  .p-vector-2 {
    left: -17.5%;
    top: 410px;
  }
}

@media (min-width: 1400px) {
  
  .prize-section {
    padding: 50px 100px;
}

.prize-title {
    font-size: 3.5rem;
}

.additional-prizes{
  /* top: 100px; */
  right: 6%;   
}
.second-place .prize-info{
  left: -70px;
}

.p-vector-1 {
  right: 0%;
  top: -120px;
}
}

@media (max-width: 360px) {
  .prize-section {
    height: 120vh;
}
}

