.main-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 270vh;
  background-color: #0a1f25;
  overflow: hidden;
}

.hero {
  position: relative;
  width: 100%;
  background-color: #0a1f25;
  top: 0;
  z-index: 2;
}

.screen-two {
  position: absolute;
  height: 100vh;
  width: 100%;
  background-color: #ffffff;
  z-index: 1;
}

.screen-three {
  z-index: 1;
  position: relative;
  width: 100%;
  height: 80vh;
  margin-top: 50%; 
  background-color: #0a1f25;
}

@media screen and (max-width: 768px) {
  .hero {
    z-index: 1;
  }
}

@media screen and (max-width: 480px) {
  .main-container {
    height: 150vh;
  }

  .hero {
    z-index: 1;
  }
  .screen-three {
    margin-top: 180%;
    height: 75vh;
  }
  
}

.custom-cursor {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  transform: translate(-20%, -20%);
  background-color: #ffffff;
  mix-blend-mode: difference; /* Inverts the color of everything behind it */
  z-index: 9999;
  transition: width 0.2s ease, height 0.2s ease; /* Smooth transition */
}

@media screen and (max-width: 1400px){
  .main-container {
    height: 280vh;
  }
}

@media screen and (max-width: 768px){
  .custom-cursor {
    display: none;
  }
}


@media (max-width: 360px) {
  .main-container {
    height: 200vh;
  }
}