.container-screen-three {
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: #0A1F25;
 }
 
 .center-container {
   position: relative;
   z-index: 10;
   margin-right: 8%;
   width: 90%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   align-items: center;
   justify-content: center;
   border-radius:  10px;
 }
 
 .browser-window {
   width: 100%;
   height: 70vh;
   background-color: #1C292C;
   justify-content: center;
   border-radius: 10px;
   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
   color: #fff;
   font-family: 'Space Mono';
 }
 
 .browser-header {
   display: flex;
   justify-content: flex-end;
   align-items: center;
   padding-bottom: 10px;
   height: 17.5px;
   border-top-left-radius: 10px;
   border-top-right-radius: 10px;
   background-color: #858585; 
 }
 
 .browser-buttons {
   display: flex;
   gap: 5px;
 }
 
 .button {
   width: 15px;
   height: 15px;
   border-radius: 50%;
   background-color : white;
 }
 
 
 
 .content {
   display: flex;
   flex-direction: column;
   background-color: #1C292C;
   justify-content: center;
   align-items: center;
   padding: 1% 12.5%;
 }
 
 .content img {
   font-weight: bold;
   text-align: center;
  width: 115%;
  margin-left: -10%;
 }
 
 .content p {
   font-size: 1rem;
   line-height: 1.4;
   text-align: center;
   margin-top: -5%;
   opacity: 0.8;
 }
 
 .boxes-container {
   /* background-color: #34c849; */
   display: flex;
   z-index: 100;
   top : 10%;
   height: 100px;
   width: 100px;

 }

 .big-container {
   position : relative;
   width : 80% ;
   display: flex;
   flex-direction: row;
   justify-content: center;
 

 }

 .box-start-container {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
 }

 .mobile-responsive-view {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #0A1F25;
    height: 100vh; 

    padding: 20px;
 }

 .mobile-center-container {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
 

   width: 80%;
   padding: 20px; 
 }

 .mobile-desc-text {
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    font-family: "Space Mono", monospace;
    color: #fff;
 }
 .text-one {
    position: absolute;
    z-index: 10;
  } 

.desc {
  font-family: "Space Mono", monospace;
}
 @media only screen and (max-width: 768px)  {
  .container-screen-three {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0A1F25;
    height: 18vh; /* Full height of the viewport */
    padding: 20px; /* Add padding for spacing on small screens */
  }

  .center-container {
    width: 90%; /* Adjust width to fit smaller screens */
    height: auto;
    background-color: #000;
    border: none;
    border-radius: 10px;
    padding: 10px;
  }

  .browser-window {
    width: 100%;
    height: auto;
    background-color: #1C292C;
    border-radius: 10px;
    color: #fff;
    text-align: center;
    padding: 20px; /* Space out the content */
  }

  .content img {
    width: 90%; /* Ensure the image scales appropriately */
    height: auto;
    margin: 0 auto 20px auto; /* Center and space out the image */
  }

  .content p {
    font-size: 14px; /* Adjust font size for readability on small screens */
    line-height: 1.5;
    font-family: 'space-grotesk', sans-serif;
  }

  .text-one {
    display: none;
  }

  .main-screen-three {
    display:  none;
  }

  .mobile-responsive-view {
    display: flex;
    height: 80vh;
  }
    .mobile-responsive-view p{
      padding-top: 10%;
  }
  .mobile-responsive-view img{
    margin-left: 5%;
}
}

@media only screen and (max-width: 980)  {
  .container-screen-three {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0A1F25;
    height: 100vh; /* Full height of the viewport */
    padding: 20px; /* Add padding for spacing on small screens */
  }

  .center-container {
    width: 90%; /* Adjust width to fit smaller screens */
    height: auto;
    background-color: #000;
    border: none;
    border-radius: 10px;
    padding: 10px;
  }

  .browser-window {
    width: 100%;
    height: auto;
    background-color: #1C292C;
    border-radius: 10px;
    color: #fff;
    text-align: center;
    padding: 20px; /* Space out the content */
  }

  .content img {
    width: 80%; /* Ensure the image scales appropriately */
    height: auto;
    margin: 0 auto 20px auto; /* Center and space out the image */
  }

  .content p {
    font-size: 14px; /* Adjust font size for readability on small screens */
    line-height: 1.5;
    font-family: 'space-grotesk', sans-serif;
    margin: 0; /* Remove extra margins */
  }

  .text-one {
    display: none;
  }

  .main-screen-three {
    display:  none;
  }

  .mobile-responsive-view {
    display: flex;
  }
}


@media only screen and (min-width: 1400px)  {
  .content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 3rem;
  }

  .content img {
    width: 130%; /* Ensure the image scales appropriately */
    height: auto;
    margin-left: -15%;
  }

  .content p {
    font-size: 1.1rem; /* Adjust font size for readability on small screens */
    line-height: 1.5;
    }

    .center-container {
      width: 75%; /* Adjust width to fit smaller screens */
    }
  
    .error-box{
      height: 120% !important;
    }
    .vector-svg{
      height: 110% !important;
    }
}