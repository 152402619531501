.timeline-view-container {
    position: relative;
    overflow: hidden;
    z-index: 0;
    overflow: hidden;
}

.background-svg {
    position: absolute;
    top: 4%;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; /* Set the SVG in the background */

    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
}

.foreground-content {
    position: relative;
    z-index: 2; /* Set the timeline and other content in the foreground */
    
}




.full-screen-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #E9E9E9;
    gap: 20px;
    /* Adjust the gap as needed */
    position: relative;
    overflow: hidden;
    z-index: 3;
}



.inner-container {
    margin-top: 10%;
    /* width: 80%; */
    /* Adjust width as needed */
    padding: 5rem 0;
    /* Padding inside the inner container */
    /* border-radius: 10px; */
    /* Optional: Add border-radius for rounded corners */

    /* background: url('../assets/dottedline.svg') no-repeat center 45%; */
    background-size: auto;
    z-index: 2;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: center;
    align-items: center;
}

.card {
    width: 100%;
    /* Make the card as wide as the screen */
    display: flex;
    justify-content: center;
    /* Align content to the center */
    position: relative;
    z-index: 3;
    /* Ensure it stays above the background */
}

.card1, .card2, .card3, .card4, .card5, .card6, .card7, .card8 {
    margin: 5% 0; 
}


@media (min-width: 1024px) {
    .card1 {
        top: -30px;
        right: 87.5%;
    }
    
    .card2 {
        top: -70px;
        left: 20%;
    }
    
    .card3 {
        top: -80px;
        right: -70%;
    }
    
    .card4 {
        top: -120px;
    }
    
    .card5 {
        top: -120px;
        left: 70%;
    }
    
    .card6 {
        top: -120px;
        /* top: 60px; */
    }
    
    .card7 {
        left:30%;
        /* top: 50px; */
    }
    
    /* .card8 {
        margin: -1% 7% 5% 50%;
    } */
}

@media (max-width: 768px) {
    .background-svg {
        display: none; /* Hide the SVG on small screens */
    }

    .inner-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* align-items: center; */
        margin-left: -15%;
        gap: 10px;
    }

}

.path1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    /* Ensure it stays behind the cards */
}

/* Default styles for larger screens */
.text-background {
    color: white;
    font-family: "Space Grotesk";
    font-size: 244px;
    font-style: normal;
    font-weight: 700;
    line-height: 101%; /* 347.44px */
    letter-spacing: 3.44px;
    text-transform: capitalize;
    opacity: 0.48;
    position: absolute;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .text-background {
        font-size: 140px; /* Reduce font size for smaller screens */
        letter-spacing: 2px; /* Adjust letter-spacing for smaller font */
    }

    .button-filled, .button-outlined {
        width: 40%; /* Make the buttons full width */
    }
}


.text-background-div1 {

    position: absolute;
    top: 0%;
    left: 5%;
}


.text-background-div2 {

    position: absolute;
    top: 16%;
    left: 20%;
}



.text-background-div3 {

    position: absolute;
    top: 50%;
    left: 0%;
}


.text-background-div4 {

    position: absolute;
    top: 70%;
    left: 15%;
}

.paraSec {
    background-color: #E9E9E9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* gap: 20px; */
    /* overflow-x: hidden; */
    overflow: hidden;
    

}

.paraSec-sub {
    width: 75%;
    margin-top: 5%;
}


.header {
    position: relative;
    color: #292929;
    text-align: center;
    font-family: "Space Grotesk";
    font-size: 3.6rem;
    font-style: normal;
    font-weight: 700;
    /* line-height: 81%; */
    letter-spacing: 0.93px;
    text-transform: capitalize;
}


@media (max-width: 1023px) {
    .header {
        font-size: 50px; /* Reduce font size for smaller screens */
        line-height: 100%; /* Adjust line height for smaller screens */
    }
}

/* Default styles for larger screens */
.para {
    margin-top: 3%;
    color: #282828;
    justify-content: 'center';
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
    font-family: "Space Grotesk";
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 109%; /* 29.43px */
    letter-spacing: 1px;
    text-transform: uppercase;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .para {
        font-size: 18px; /* Reduce font size for smaller screens */
        line-height: 120%; /* Adjust line height for better readability */
    }
}

.buttonSection {
    display: flex;
    flex-direction: row; 
    align-items: center;
    justify-content: center;
    gap: 25px;
    margin: 2%;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .buttonSection {
        flex-direction: column; /* Stack buttons vertically on smaller screens */
        gap: 20px; 
        width: 120%;
        margin-top: 8%;
   
    }
    .button-filled,.button-outlined{
        width: 40%;
    }
    .header{
        margin-bottom: 5%;
    }

    .svg2{
        margin-bottom: 10%;
    }

    .svg-2{
        height: 30%;
    }
   
}

.button-filled, .button-outlined {
    width: 60%; /* Fixed width for both buttons */
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.8em;
    flex-shrink: 0;
    border-radius: 1em; /* Ensure rounded borders are applied */
    border: 1px solid #000;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    cursor: pointer;
}



.button-filled {
    background: #292929;
    text-decoration: none; /* Remove underline */

    
}

.button-filled:hover {
    border: none;
    background: linear-gradient(95deg, #0E9F9B 3.99%, #FBB934 120.91%);
}

.button-outlined {
    background: transparent;
    
}




.button-outlined:hover {
    border: 1px solid #0E9F9B;; 
    
}

.button-filled-text, .button-outlined-text {
    text-align: center;
    font-family: "Space Grotesk";
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 109%;
    letter-spacing: 0.24px;
    text-transform: uppercase;
}


.button-filled-text {
    color: #E9E9E9;
}

.button-outlined-text {
    color: #000;
}

.button-outlined-text:hover {
    background: linear-gradient(45deg, #0E9F9B, #FFB34A); /* Apply gradient */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    
}

.svg1 {
    margin-left: -20%;
    margin-bottom: 5%;
    display: flex;
    justify-content: flex-start; 
    align-items: center; 
    width: 100%; /* Default width */
    height: auto; /* Maintain aspect ratio */
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .svg1 {
        width: 75%; /* Make the SVG smaller */
        height: auto; /* Maintain aspect ratio */
        margin-left: 30px;
        margin-bottom: 10%; /* Adjust margin for smaller screens */
        margin-top: 10%;
    }
}

.svg2 {
    display: flex;
    justify-content: flex-end; 
    align-items: center; 
    width: 15%;
    height: auto; 
    margin-top: -5%;
    transform: translateX(450px);
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .svg2 {
        width: 30%;
        height: auto;
        margin-top: 10%; 
        transform: translateX(27.5px);
    }
}
.svg3 {
    position: absolute;
    top: -32.5%;
    right: 28%;
    width: 20%;

}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .svg3 {
        top: 40%; /* Adjust position lower */
        right: 18%; /* Adjust horizontal position */
        width: 65%; /* Make the SVG smaller */
        height: auto; /* Maintain aspect ratio */
    }
}

.title-area{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


