.reg-button {
   width: 100%; 
   position: relative;
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 0.8em 2em;
   border-radius: 1em;
   background: transparent;
   border: 1px solid #E9E9E9;
}

/* .reg-button:hover {
   background: linear-gradient(45deg, #0E9F9B, #FFB34A);
   background-size: 200% 100%;
   background-position: left center;
   color: transparent;
   -webkit-background-clip: text;
   background-clip: text;
   transition: background 0.4s ease, color 0.4s ease;
   transition: border 0.4s ease, color 0.4s ease;
} */

/* .button-outlined {
   background: transparent;
} */

/* .button-outlined:hover {
   border: 1px solid #0E9F9B; 
} */

.reg-button, .button-outlined-text {
   text-align: center;
   font-family: "Space Grotesk";
   font-size: 0.8rem;
   font-style: normal;
   font-weight: 400;
   line-height: 109%;
   letter-spacing: 1px;
   text-transform: uppercase;
}

.reg-button {
   color: #E9E9E9;
}

@media (max-width: 768px) {
}