.card {
  background-color: transparent;
  text-align: left;
  margin: 0 auto;
  max-width: 480px; /* Increased max width */
  display: flex;
  flex-direction: column;
  gap: 25px;
}


.card-image {
  width: 180px; /* Revert to previous smaller image size */
  height: 210px; /* Revert to previous smaller image height */
}

@media (max-width: 768px) {
  .card-image {
    width: 100px;  /* Smaller image width */
    height: 140px; /* Smaller image height */
  }
}

.card-info {
  padding: 5px; /* Reduced padding */
  border-radius: 0px;
  margin-top: -10px; /* Adjust margin to overlap the image */
  font-family: "Space Grotesk", sans-serif; 
}

.card-name {
  display: inline-block; /* Display the background color only around the text */
  font-size: 17px;
  text-align: left;
  color: black;
  background-color: white;
  padding: 2px 5px; /* Add some padding around the text */
  border-radius: 1px; /* Slightly rounded corners */
  font-family: "Space Mono", monospace;
  margin-bottom: 5px;
}

.card-position,
.card-club,
.card-email,
.card-contact {
  color: white;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500; 
  text-align: left;
  margin-left: 2px; /* Reduced margin */
  text-transform: capitalize; 
}

@media (max-width: 600px) {
  .card {
    margin: 0 auto; /* Center the card horizontally */
    margin-bottom: 10%; /* Add some space between cards */
    max-width: 200px; /* Maintain smaller card size */
  }

  .card-image {
    width:  150px; /* Maintain smaller image size */
    height: auto; /* Maintain smaller image height */
  }
}

@media (max-width: 768px) {
  .card {
    margin: 0 auto; /* Center the card horizontally */
    max-width: 225px; /* Maintain smaller card size */
  }

  .card-image {
    width: 150px; /* Maintain smaller image size */
    height: auto; /* Maintain smaller image height */
  }
}